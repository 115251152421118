export const query = (
  params: string
) => `const documentClient = new AWS.DynamoDB.DocumentClient();
const params = ${params};

const result = await documentClient.query(params).promise();
`

export const scan = (
  params: string
) => `const documentClient = new AWS.DynamoDB.DocumentClient();
const params = ${params};

const result = await documentClient.scan(params).promise();
`
