import forgeParams from "./forgers/typescript";
import { FilterExpression } from "./types/FilterExpression";
import { DynamoDBIndex } from "./types/TableDescription";
import { Tab } from "./types/Tab";

const computeFetchMessageRequest = (
  tab: Tab,
  filterExpressions: FilterExpression[],
  index?: DynamoDBIndex
): any => {
  const commonParams = {};

  if (index) {
    const primaryKeyFilterExpression = filterExpressions.find(
      expr => expr.attributeName === index.KeySchema[0].AttributeName
    );

    const sortKeyFilterExpression = index.KeySchema[1]
      ? filterExpressions.find(expr => expr.attributeName === index.KeySchema[1].AttributeName)
      : undefined;

    let filterExpressionsFiltered = filterExpressions.filter(
      expr => expr.attributeName !== index.KeySchema[0].AttributeName
    );

    if (sortKeyFilterExpression) {
      filterExpressionsFiltered = filterExpressionsFiltered.filter(
        expr => expr.attributeName !== index.KeySchema[1].AttributeName
      );
    }

    return {
      ...commonParams,
      operationType: "QUERY",
      params: forgeParams({
        TableName: tab.TableName,
        operationType: "QUERY",
        IndexName: index.IndexName,
        filterExpressions: filterExpressionsFiltered,
        ScanIndexForward: true,
        keySchema: index.KeySchema,
        partitionKeyValue: primaryKeyFilterExpression!.attributeValue,
        sortKeyValue: sortKeyFilterExpression ? sortKeyFilterExpression.attributeValue : "",
        sortKeyOperator: sortKeyFilterExpression ? sortKeyFilterExpression.operator : "="
      })
    };
  }

  return {
    ...commonParams,
    operationType: "SCAN",
    params: forgeParams({
      TableName: tab.TableName,
      operationType: "SCAN",
      IndexName: "table",
      filterExpressions: filterExpressions,
      ScanIndexForward: true,
      keySchema: tab!.KeySchema,
      partitionKeyValue: "",
      sortKeyValue: "",
      sortKeyOperator: "="
    })
  };
};

export default computeFetchMessageRequest;
