import React from "react"
import { Box } from "rebass"
import FAQJsonLD from "@components/FAQJsonLD"

const QueryFAQ = () => (
  <Box mt={62}>
    <FAQJsonLD
      questions={[
        {
          question: "Can you query DynamoDB?",
          answer:
            "Of course you can query <a href='https://dynobase.dev/'>AWS DynamoDB</a> to fetch a collection of items. Using Query operation is one of the three ways to fetch data from DynamoDB (apart from Scan and GetItem).",
        },
        {
          question: "Does DynamoDB support SQL queries?",
          answer:
            "Yes! Recently AWS announced PartiQL support which means that you can use SQL-like language to query DynamoDB. Keep in mind that using PartiQL to query DynamoDB has the same limitations as native query.",
        },
        {
          question:
            "What are all the keys mentioned in the creator? Partition key, sort key, composite key and so on...",
          answer:
            "You can read about them in this <a href='https://dynobase.dev/dynamodb-keys/'>article about DynamoDB Keys.</a>.",
        },
        {
          question: "When should I use DynamoDB Query?",
          answer:
            "You should use DynamoDB Query if you want to fetch a collection of data from the same partition. It means that items that you need to fetch need to have the same partition key. If your items are not having the same partition key, you'll probably have to use a series of GetItem operations or one Scan with relevant FilterExpressions.",
        },
        {
          question: "How do I query DynamoDB GSI?",
          answer:
            "All you need to do is add a 'IndexName' parameter to your expression and update both ExpressionAttributeNames and KeyConditionExpression to match the selected index schema. You can read <a href='https://dynobase.dev/dynamodb-indexes'>more about GSIs (and other indexes) here.</a>",
        },
        {
          question: "Can we query DynamoDB without Primary Key?",
          answer:
            "No. DynamoDB Query requires a primary key because it tells DynamoDB engine which partition of your table contains desired data. If you want to fetch a collection of items without explicitly definiting PartitionKey, you need to use Scan operation",
        },
      ]}
    />
    <h2>Frequently Asked Questions</h2>
    <h3>Can you query DynamoDB?</h3>
    <p>
      Of course you can query <a href="https://dynobase.dev/">AWS DynamoDB</a>{" "}
      to fetch a collection of items. Using Query operation is one of the three
      ways to fetch data from DynamoDB (apart from Scan and GetItem). Read about
      differences between{" "}
      <a href="/dynamodb-scan-vs-query/">Scan and Query here</a>
    </p>

    <h3>Does DynamoDB support SQL queries?</h3>
    <p>
      Yes! Recently AWS announced PartiQL support which means that you can use
      SQL-like language to query DynamoDB. Keep in mind that using PartiQL to
      query DynamoDB has the same limitations as native query. You can learn
      more about <a href="/dynamodb-sql/">PartiQL support in Dynobase here.</a>
    </p>

    <h3>When should I use DynamoDB Query?</h3>
    <p>
      You should use DynamoDB Query if you want to fetch a collection of data
      from the same partition. It means that items that you need to fetch need
      to have the same partition key. If your items are not having the same
      partition key, you'll probably have to use a series of GetItem operations
      or one Scan with relevant FilterExpressions.
    </p>

    <h3>How do I query DynamoDB GSI?</h3>
    <p>
      All you need to do is add a 'IndexName' parameter to your expression and
      update both ExpressionAttributeNames and KeyConditionExpression to match
      the selected index schema.
    </p>

    <h3>Can we query DynamoDB without Primary Key?</h3>
    <p>
      No. DynamoDB Query requires a primary key because it tells DynamoDB engine
      which partition of your table contains desired data. If you want to fetch
      a collection of items without explicitly definiting PartitionKey, you need
      to use <a href="/dynamodb-scan/">Scan operation</a>
    </p>
  </Box>
)

export default QueryFAQ
