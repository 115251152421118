export enum AttributeType {
  String = "String",
  Binary = "Binary",
  Number = "Number",
  Boolean = "Boolean",
  Null = "Null"
}

export enum NullOperator {
  Exists = "Exists",
  NotExists = "Not Exists"
}

export enum BoolOperator {
  Exists = "Exists",
  NotExists = "Not Exists",
  Equal = "=",
  NotEqual = "!="
}

export enum NumberOperator {
  Exists = "Exists",
  NotExists = "Not Exists",
  Equal = "=",
  NotEqual = "!=",
  GreaterThan = ">",
  LessThan = "<",
  GreaterEqualThan = ">=",
  LessEqualThan = "<="
  // Between = "Between"
}

export enum StringOperator {
  Exists = "Exists",
  NotExists = "Not Exists",
  Equal = "=",
  NotEqual = "!=",
  GreaterThan = ">",
  LessThan = "<",
  GreaterEqualThan = ">=",
  LessEqualThan = "<=",
  // Between = "Between",
  Contains = "Contains",
  NotContains = "Not Contains",
  BeginsWith = "Begins With"
}

export enum BinaryOperator {
  Exists = "Exists",
  NotExists = "Not Exists",
  Equal = "=",
  NotEqual = "!=",
  GreaterThan = ">",
  LessThan = "<",
  GreaterEqualThan = ">=",
  LessEqualThan = "<=",
  // Between = "Between",
  Contains = "Contains",
  NotContains = "Not Contains"
}

export type FilterExpression = {
  id: string;
  attributeName: string;
  attributeValue: string;
  attributeValueTo?: string;
  attributeType: AttributeType;
  operator: NullOperator | BoolOperator | NumberOperator | BinaryOperator | StringOperator;
  logicalEvaluation: "AND" | "OR";
  createdAt: number;
  queryIndexName?: string;
};
