import { FilterExpression } from "../types/FilterExpression"
import { StringOperator, AttributeType } from "../types/FilterExpression"
import createCtx from "./createCtx"
import { DynamoDBIndex } from "../types/TableDescription"

export interface FilterExpressionsContextInterface {
  filterExpressions: FilterExpression[]
  indexes?: DynamoDBIndex[]
  usedIndex?: DynamoDBIndex
  usableIndexes?: DynamoDBIndex[]
}

type FilterExpressionAction =
  | { type: "SET_INDEXES"; indexes: DynamoDBIndex[] }
  | { type: "USE_INDEX"; indexName: string }
  | {
      type: "ADD_NEW_FILTER_EXPRESSION"
      attributeName?: string
      attributeValue?: string
      operator?: StringOperator
    }
  | {
      type: "CHANGE_FILTER_EXPRESSION"
      newFilterExpression: FilterExpression
      id: string
    }
  | { type: "REMOVE_FILTER_EXPRESSION"; id: string }
  | { type: "SET"; state: FilterExpressionsContextInterface }

export const generateTabKey = () =>
  Math.random()
    .toString(36)
    .substring(2, 15)

const defaultValue: FilterExpressionsContextInterface = {
  indexes: [],
  usedIndex: undefined,
  usableIndexes: [],
  filterExpressions: [
    {
      id: generateTabKey(),
      attributeName: "",
      attributeValue: "",
      attributeType: AttributeType.String,
      operator: StringOperator.BeginsWith,
      createdAt: +new Date(),
      logicalEvaluation: "AND",
    },
  ],
}

const setQueryIndexNames = (state: FilterExpressionsContextInterface) => {
  let found

  state.indexes!.forEach(index =>
    state.filterExpressions.find(filterExpression => {
      if (index.KeySchema[0].AttributeName === filterExpression.attributeName) {
        filterExpression.queryIndexName = index.IndexName
        found = filterExpression
      } else {
        filterExpression.queryIndexName = undefined
      }
    })
  )

  return found
}

const setQueryUsableIndexes = (state: FilterExpressionsContextInterface) =>
  state.indexes!.filter(index =>
    state.filterExpressions.find(
      filterExpression =>
        index.KeySchema[0].AttributeName === filterExpression.attributeName
    )
  )

const setQueryIndexNamesForIndex = (
  state: FilterExpressionsContextInterface,
  index?: DynamoDBIndex
) =>
  state.filterExpressions.map(filterExpression => {
    if (
      index &&
      index.KeySchema[0].AttributeName === filterExpression.attributeName
    ) {
      filterExpression.queryIndexName = index.IndexName
    } else {
      filterExpression.queryIndexName = undefined
    }

    return filterExpression
  })

function reducer(
  state: FilterExpressionsContextInterface,
  action: FilterExpressionAction
): FilterExpressionsContextInterface {
  switch (action.type) {
    case "SET_INDEXES":
      return {
        ...state,
        indexes: action.indexes,
      }
    case "USE_INDEX":
      if (state.usableIndexes && state.usableIndexes.length > 0) {
        state.usedIndex = state.usableIndexes.find(
          i => i.IndexName === action.indexName
        )

        setQueryIndexNamesForIndex(state, state.usedIndex!)
      }

      return {
        ...state,
      }
    case "ADD_NEW_FILTER_EXPRESSION":
      state.filterExpressions = [
        ...(state.filterExpressions || []),
        {
          id: generateTabKey(),
          attributeName: action.attributeName || "",
          attributeValue: action.attributeValue || "",
          attributeType: AttributeType.String,
          operator: action.operator || StringOperator.BeginsWith,
          createdAt: +new Date(),
          logicalEvaluation: "AND",
        },
      ]

      return {
        ...state,
      }
    case "CHANGE_FILTER_EXPRESSION":
      let filterExpressions = (state.filterExpressions || []).filter(
        expr => expr.id !== action.id
      )

      state.filterExpressions = [
        ...filterExpressions,
        action.newFilterExpression,
      ].sort((a, b) => a.createdAt - b.createdAt)

      state.usableIndexes = setQueryUsableIndexes(state)
      if (!state.usedIndex && state.usableIndexes.length > 0) {
        state.usedIndex = state.usableIndexes[0]

        setQueryIndexNamesForIndex(state, state.usedIndex!)
      } else if (
        state.usedIndex &&
        !state.usableIndexes.find(
          ind => ind.IndexName === state.usedIndex!.IndexName
        )
      ) {
        state.usedIndex = state.usableIndexes[0]
        state.filterExpressions = setQueryIndexNamesForIndex(
          state,
          state.usedIndex
        )
      } else {
        state.filterExpressions = setQueryIndexNamesForIndex(
          state,
          state.usedIndex
        )
      }

      return {
        ...state,
      }
    case "REMOVE_FILTER_EXPRESSION":
      state.filterExpressions = (state.filterExpressions || [])
        .filter(expr => expr.id !== action.id)
        .sort((a, b) => a.createdAt - b.createdAt)

      state.usableIndexes = setQueryUsableIndexes(state)
      if (
        state.usedIndex &&
        !state.usableIndexes.find(
          ind => ind.IndexName === state.usedIndex!.IndexName
        )
      ) {
        state.usedIndex = undefined
      }

      return {
        ...state,
      }
    case "SET":
      return {
        ...action.state,
      }
  }
}

const [ctx, CountProvider] = createCtx(reducer, defaultValue)

export const Context = ctx
export const Provider = CountProvider
