import React from 'react';

import IconButtonProps from './IconButtonProps';

const IconButton: React.FC<IconButtonProps> = (props: IconButtonProps) => {
  return (
    <div onClick={props.onClick} style={{ display: 'inline', cursor: 'pointer', ...props.style }}>
      <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 33 33'>
        <g fill={props.fill || '#535353'} fillRule='nonzero'>
          <path d='M8 16h17v2H8z' />
          <path d='M16.5 33C7.405 33 0 25.595 0 16.5 0 7.405 7.405 0 16.5 0 25.595 0 33 7.405 33 16.5 33 25.595 25.595 33 16.5 33zm0-31.429C8.27 1.571 1.571 8.27 1.571 16.5S8.27 31.429 16.5 31.429 31.429 24.73 31.429 16.5 24.73 1.571 16.5 1.571z' />
        </g>
      </svg>
    </div>
  );
};

export default IconButton;
