import React from "react"
import { Input } from "@rebass/forms"
import { Flex, Text } from "rebass"
import { Tab, GlobalSecondaryIndex } from "../types/Tab"
import RemoveIcon from "./icons/Remove"

interface KeySchemaBuilderProps {
  tab: Tab
  setTab: (tab: Tab) => void
}

const renderKey = (
  gsi: GlobalSecondaryIndex,
  changeGSI: (id: string, arg2: any) => void,
  removeGSI: (id: string) => void
) => {
  return (
    <Flex mb={1} mt={2} alignItems={"center"} key={gsi.IndexArn}>
      <Input
        name="indexName"
        style={{ border: "1px solid rgb(213, 213, 213)", outline: "none" }}
        value={gsi.IndexName}
        width="53px"
        fontSize="16px"
        height="36px"
        fontFamily="system-ui"
        placeholder="Index Name"
        onChange={(value: React.ChangeEvent<HTMLInputElement>) => {
          changeGSI(gsi.IndexArn, {
            ...gsi,
            IndexName: value.target.value,
          })
        }}
      />
      <Input
        name="partitionkey"
        style={{ border: "1px solid rgb(213, 213, 213)", outline: "none" }}
        value={gsi.KeySchema[0].AttributeName}
        autoComplete="off"
        mr={3}
        fontSize="16px"
        height="36px"
        fontFamily="system-ui"
        placeholder="Partition/Hash key"
        width={1 / 3}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          changeGSI(gsi.IndexArn, {
            ...gsi,
            KeySchema: [
              {
                AttributeName: e.target.value,
              },
              gsi.KeySchema[1],
            ],
          })
        }}
      />
      <Input
        id="sortkey"
        name="sortkey"
        fontSize="16px"
        height="36px"
        width={1 / 3}
        value={gsi.KeySchema[1].AttributeName}
        placeholder="Sort/Range Key (optional)"
        style={{
          marginRight: "20px",
          borderColor: "rgb(213, 213, 213)",
          outline: "none",
          padding: "8px",
        }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          changeGSI(gsi.IndexArn, {
            ...gsi,
            KeySchema: [
              gsi.KeySchema[0],
              {
                AttributeName: e.target.value,
              },
            ],
          })
        }}
      />
      <RemoveIcon onClick={() => removeGSI(gsi.IndexArn)} />
    </Flex>
  )
}

const KeySchemaBuilder: React.FC<KeySchemaBuilderProps> = (
  props: KeySchemaBuilderProps
) => {
  const changeGSI = (id: string, arg2: any) => {
    props.setTab({
      ...props.tab,
      GlobalSecondaryIndexes: [
        arg2,
        ...(props.tab.GlobalSecondaryIndexes || []).filter(
          g => g.IndexArn !== id
        ),
      ],
    })
  }
  const removeGSI = (id: string) => {
    props.setTab({
      ...props.tab,
      GlobalSecondaryIndexes: [
        ...(props.tab.GlobalSecondaryIndexes || []).filter(
          g => g.IndexArn !== id
        ),
      ],
    })
  }

  return (
    <div>
      {(props.tab.GlobalSecondaryIndexes || []).map(gsi =>
        renderKey(gsi, changeGSI, removeGSI)
      )}

      <Text
        ml={2}
        mt={3}
        mb={3}
        onClick={() =>
          props.setTab({
            ...props.tab,
            GlobalSecondaryIndexes: [
              ...(props.tab.GlobalSecondaryIndexes || []),
              {
                KeySchema: [
                  {
                    AttributeName: "",
                    KeyType: "HASH",
                  },
                  {
                    AttributeName: "",
                    KeyType: "RANGE ",
                  },
                ],
                IndexName: `gsi_${(props.tab.GlobalSecondaryIndexes || [])
                  .length + 1}`,
                IndexArn: Math.random()
                  .toString(36)
                  .substring(2, 15),
              },
            ],
          })
        }
        fontSize={"12px"}
        style={{ cursor: "pointer", width: "max-content", color: "#5b5b5b" }}
      >
        + Add Secondary Index
      </Text>
    </div>
  )
}

export default KeySchemaBuilder
