import React, { useContext, useState } from "react"
import { Flex, Box, Text, Button } from "rebass"
import { Input, Textarea, Select } from "@rebass/forms"
import Editor from "react-simple-code-editor"
import SimpleFilter from "./SimpleFilter"
import computeFetchMessageRequest from "../utils"
import { Tab } from "../types/Tab"
import { Context } from "../state/filterExpressions"
import KeySchemaBuilder from "./KeySchemaBuilder"
import { query, scan } from "../consts/javascript"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { highlight, languages } from "prismjs"
import "prismjs/components/prism-clike"
import "prismjs/components/prism-javascript"

const QueryBuilder = () => {
  const { state, dispatch } = useContext(Context)

  const [tab, changeTab] = useState<Tab>({
    AttributeDefinitions: [
      {
        AttributeName: "id",
        AttributeType: "S",
      },
    ],
    KeySchema: [
      {
        AttributeName: "",
        KeyType: "HASH",
      },
      {
        AttributeName: "",
        KeyType: "HASH",
      },
    ],
    TableName: "my-sample-table",
  })
  const [copiedCode, showCopiedCode] = useState(false)

  const setTab = (tab: Tab) => {
    changeTab(tab)
    dispatch({
      type: "SET_INDEXES",
      indexes: [
        { KeySchema: tab.KeySchema, IndexName: "table" },
        ...(tab.GlobalSecondaryIndexes || []),
      ],
    })
  }

  const fetchMessageRequest = computeFetchMessageRequest(
    tab,
    state.filterExpressions,
    state.usedIndex
  )

  const textAreaValue =
    fetchMessageRequest.operationType === "QUERY"
      ? query(JSON.stringify(fetchMessageRequest.params, null, 2))
      : scan(JSON.stringify(fetchMessageRequest.params, null, 2))

  return (
    <Flex flexDirection={["column", "column", "row"]} mx={[32, 62, 0]}>
      <Box
        width={[1, 1, 1 / 2]}
        pr={[0, 0, 4]}
        pl={[0, 0, 80]}
        className="left-column"
        mt={4}
      >
        <Box mt={62} pb={0}>
          <h1>DynamoDB Visual Query Builder</h1>
          <p>
            Construct complex DynamoDB queries without having to learn
            DynamoDB's query syntax. A rich set of visual options are available
            to let you combine filters with partition/sort key and global
            secondary index.
            <br /> <br />
            Based on your selections, the query builder will generate query code
            for DynamoDB CLI and popular languages SDK formats (DynamoDB
            DocumentClient/DynamoDB JavaScript/Typescript SDK, Java, Golang,
            Boto 3 aka Python).
            <br /> <br />
            Soon, we'll also be adding the option to create queries using SQL.
            In the meantime, you can have a look at{" "}
            <a href="/dynamodb-sql/">our PartiQL support</a>.
          </p>

          <p>
            If you're not experienced with DynamoDB and want to see how our tool
            handles complex queries, click the button below to load complex
            schema and query.
          </p>
        </Box>
        <Text my={1} fontWeight="550" color="#535353">
          Table Name
        </Text>
        <Input
          name="tableName"
          placeholder="Table Name"
          autoComplete="off"
          width={"200px"}
          fontSize="16px"
          height="36px"
          fontFamily="system-ui"
          style={{ border: "1px solid rgb(213, 213, 213)", outline: "none" }}
          value={tab.TableName}
          onChange={(e: any) =>
            setTab({
              ...tab,
              TableName: e.target.value,
            })
          }
        />
        <Text mb={1} mt={4} fontWeight="550" color="#535353">
          Table Index
        </Text>
        <Flex flexDirection="row">
          <Input
            name="partitionKey"
            placeholder="Partition/Hash Key"
            autoComplete="off"
            width={"200px"}
            fontFamily="system-ui"
            fontSize="16px"
            height="36px"
            style={{ border: "1px solid rgb(213, 213, 213)", outline: "none" }}
            value={tab.KeySchema[0].AttributeName}
            onChange={(e: any) =>
              setTab({
                ...tab,
                KeySchema: [
                  {
                    AttributeName: e.target.value,
                    KeyType: "HASH",
                  },
                  tab.KeySchema[1],
                ],
              })
            }
          />
          <Input
            name="sortKey"
            ml={16}
            autoComplete="off"
            fontSize="16px"
            height="36px"
            fontFamily="system-ui"
            placeholder="Range/Sort Key (optional)"
            width={"200px"}
            style={{ border: "1px solid rgb(213, 213, 213)", outline: "none" }}
            value={tab.KeySchema[1].AttributeName}
            onChange={(e: any) =>
              setTab({
                ...tab,
                KeySchema: [
                  tab.KeySchema[0],
                  {
                    AttributeName: e.target.value,
                    KeyType: "HASH",
                  },
                ],
              })
            }
          />
        </Flex>

        <KeySchemaBuilder tab={tab} setTab={setTab} />
        <Text mb={1} mt={4} fontWeight="550" color="#535353">
          Filters
        </Text>
        <SimpleFilter tab={tab} />

        <Button
          onClick={() => {
            setTab({
              ...tab,
              KeySchema: [
                {
                  AttributeName: "pk",
                  KeyType: "HASH",
                },
                {
                  AttributeName: "sk",
                  KeyType: "RANGE",
                },
              ],
              GlobalSecondaryIndexes: [
                {
                  KeySchema: [
                    {
                      AttributeName: "sk",
                      KeyType: "HASH",
                    },
                    {
                      AttributeName: "model",
                      KeyType: "RANGE ",
                    },
                  ],
                  IndexName: `gsi_1`,
                  IndexArn: Math.random().toString(36).substring(2, 15),
                },
                {
                  KeySchema: [
                    {
                      AttributeName: "ref",
                      KeyType: "HASH",
                    },
                    {
                      AttributeName: "sk",
                      KeyType: "RANGE ",
                    },
                  ],
                  IndexName: `gsi_2`,
                  IndexArn: Math.random().toString(36).substring(2, 15),
                },
                {
                  KeySchema: [
                    {
                      AttributeName: "model",
                      KeyType: "HASH",
                    },
                    {
                      AttributeName: "pk",
                      KeyType: "RANGE ",
                    },
                  ],
                  IndexName: `gsi_3`,
                  IndexArn: Math.random().toString(36).substring(2, 15),
                },
              ],
            })

            dispatch({
              type: "SET",
              state: {
                indexes: [
                  {
                    KeySchema: [
                      {
                        AttributeName: "pk",
                        KeyType: "HASH",
                      },
                      {
                        AttributeName: "sk",
                        KeyType: "RANGE",
                      },
                    ],
                    IndexName: "table",
                  },
                  {
                    KeySchema: [
                      {
                        AttributeName: "model",
                      },
                      {
                        AttributeName: "pk",
                        KeyType: "RANGE ",
                      },
                    ],
                    IndexName: "gsi_3",
                    IndexArn: "m9ruqd3rn99",
                  },
                  {
                    KeySchema: [
                      {
                        AttributeName: "sk",
                        KeyType: "HASH",
                      },
                      {
                        AttributeName: "model",
                        KeyType: "RANGE ",
                      },
                    ],
                    IndexName: "gsi_1",
                    IndexArn: "fqz7d2rn9n",
                  },
                  {
                    KeySchema: [
                      {
                        AttributeName: "ref",
                        KeyType: "HASH",
                      },
                      {
                        AttributeName: "sk",
                        KeyType: "RANGE ",
                      },
                    ],
                    IndexName: "gsi_2",
                    IndexArn: "81v545cud9v",
                  },
                ],
                usedIndex: {
                  KeySchema: [
                    {
                      AttributeName: "pk",
                      KeyType: "HASH",
                    },
                    {
                      AttributeName: "sk",
                      KeyType: "RANGE",
                    },
                  ],
                  IndexName: "table",
                },
                usableIndexes: [
                  {
                    KeySchema: [
                      {
                        AttributeName: "pk",
                        KeyType: "HASH",
                      },
                      {
                        AttributeName: "sk",
                        KeyType: "RANGE",
                      },
                    ],
                    IndexName: "table",
                  },
                  {
                    KeySchema: [
                      {
                        AttributeName: "model",
                      },
                      {
                        AttributeName: "pk",
                        KeyType: "RANGE ",
                      },
                    ],
                    IndexName: "gsi_3",
                    IndexArn: "m9ruqd3rn99",
                  },
                  {
                    KeySchema: [
                      {
                        AttributeName: "sk",
                        KeyType: "HASH",
                      },
                      {
                        AttributeName: "model",
                        KeyType: "RANGE ",
                      },
                    ],
                    IndexName: "gsi_1",
                    IndexArn: "fqz7d2rn9n",
                  },
                ],
                filterExpressions: [
                  {
                    id: "djstn6cb77t",
                    attributeName: "pk",
                    attributeValue: "Member-123",
                    attributeType: "String",
                    operator: "Begins With",
                    createdAt: 1582557082397,
                    logicalEvaluation: "AND",
                    queryIndexName: "table",
                  },
                  {
                    id: "igi0rsekrk",
                    attributeName: "model",
                    attributeValue: "MEMBER",
                    attributeType: "String",
                    operator: "=",
                    createdAt: 1582557292266,
                    logicalEvaluation: "AND",
                  },
                  {
                    id: "ldg8l4h0nd",
                    attributeName: "sk",
                    attributeValue: "Role-administrator",
                    attributeType: "String",
                    operator: "=",
                    createdAt: 1582557305556,
                    logicalEvaluation: "AND",
                  },
                  {
                    id: "qqa4iz8qdc",
                    attributeName: "deletedAt",
                    attributeValue: "",
                    attributeType: "String",
                    operator: "Not Exists",
                    createdAt: 1582557316720,
                    logicalEvaluation: "AND",
                  },
                  {
                    id: "cu3w5zeg3wd",
                    attributeName: "updatedAt",
                    attributeValue: "2020-02",
                    attributeType: "String",
                    operator: "Begins With",
                    createdAt: 1582557321122,
                    logicalEvaluation: "AND",
                  },
                ],
              },
            })
          }}
          style={{
            color: "rgb(24, 144, 255)",
            fontWeight: 600,
            boxShadow:
              "rgba(136, 144, 195, 0.2) 0px 2px 4px 0px, rgba(37, 44, 97, 0.35) 0px 5px 15px 0px",
            outline: 0,
            cursor: "pointer",
            marginBottom: "32px",
            backgroundColor: "white",
          }}
        >
          Load Complex Query
        </Button>
      </Box>
      <Box
        width={[1, 1, 1 / 2]}
        px={3}
        bg="#f8f8f8"
        pt={[0, 0, 4]}
        mb={[62, 62, 0]}
        borderColor="rgb(213,213,213)"
        borderRadius={0}
      >
        <Box mb={3} borderColor="rgb(213,213,213)" borderRadius={0}>
          <Select
            fontFamily="system-ui"
            py={[2]}
            width={[0.85, 0.7, 0.6]}
            style={{
              outline: "none",
              backgroundColor: "white",
              borderColor: "rgb(213,213,213)",
              borderRadius: 0,
            }}
            mt={[3, 3, 0]}
            borderColor="rgb(213,213,213)"
            borderRadius={0}
          >
            <option>AWS Javascript/Typescript DocumentClient SDK</option>
            <option disabled>AWS CLI (soon)</option>
            <option disabled>
              AWS Javascript/Typescript DynamoDB SDK (soon)
            </option>
            <option disabled>AWS Java DynamoDB SDK (soon)</option>
            <option disabled>AWS Golang DynamoDB SDK (soon)</option>
            <option disabled>boto3 SDK (soon)</option>
          </Select>
        </Box>
        <Editor
          value={textAreaValue}
          highlight={(code) =>
            highlight(code, languages.javascript, "javascript")
          }
          style={{
            fontFamily: "hack,monospace,sans-serif",
            fontSize: 14,
            padding: "18px",
            // border: "solid 0.5px rgb(213, 213, 213)",
            borderRadius: "5px",
            outline: 0,
            backgroundColor: "#333",
            marginBottom: "10px",
            minHeight: "540px",
            color: "rgb(80, 250, 123)",
          }}
        />
        <Flex my={22}>
          <CopyToClipboard
            text={textAreaValue}
            onCopy={() => showCopiedCode(true)}
          >
            {/* <Button
              color="#535353"
              variant="primary"
              style={{ cursor: "pointer", outline: "none" }}
            >
              Copy to Clipboard
            </Button> */}

            <Button
              style={{
                color: "rgb(24, 144, 255)",
                fontWeight: 600,
                boxShadow:
                  "rgba(136, 144, 195, 0.2) 0px 2px 4px 0px, rgba(37, 44, 97, 0.35) 0px 5px 15px 0px",
                outline: 0,
                cursor: "pointer",
                backgroundColor: "white",
              }}
            >
              Copy to Clipboard
            </Button>
          </CopyToClipboard>

          {copiedCode && (
            <p style={{ margin: "auto 0", paddingLeft: "16px" }}>Copied</p>
          )}
        </Flex>

        {/* <Textarea
          bg="#FFF"
          width="98%"
          height="669px"
          mb={5}
          style={{
            border: "1px solid rgb(169, 169, 169)",
            resize: "none",
            outline: "none",
          }}
          value={textAreaValue}
        /> */}
      </Box>
    </Flex>
  )
}

export default QueryBuilder
