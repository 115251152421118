import ForgerInput from "./ForgerInput";
import { FilterExpression } from "../types/FilterExpression";

const computeSortKeyClause = (attribute: string, operator: string) => {
  switch (operator) {
    case "Begins With":
      return `begins_with(${attribute}, :skey)`;
    case "Between":
      return `${attribute} BETWEEN :skey and :to_skey`;
    default:
      return `${attribute} ${operator} :skey`;
  }
};

const computePartialFilterExpression = (attributeName: string, operator: string): string => {
  if (operator === "Exists") {
    return `attribute_exists(#DYNOBASE_${attributeName})`;
  } else if (operator === "Not Exists") {
    return `attribute_not_exists(#DYNOBASE_${attributeName})`;
  } else if (operator === "Begins With") {
    return `begins_with(#DYNOBASE_${attributeName}, :${attributeName})`;
  } else if (operator === "Contains") {
    return `contains(#DYNOBASE_${attributeName}, :${attributeName})`;
  } else if (operator === "Not Contains") {
    return `NOT contains(#DYNOBASE_${attributeName}, :${attributeName})`;
  } else if (operator === "!=") {
    return `#DYNOBASE_${attributeName} <> :${attributeName}`;
  } else {
    return `#DYNOBASE_${attributeName} ${operator} :${attributeName}`;
  }
};

const forgeParams = ({
  TableName,
  IndexName,
  ExclusiveStartKey,
  filterExpressions,
  ScanIndexForward,
  keySchema,
  partitionKeyValue,
  sortKeyOperator,
  sortKeyValue,
  sortKeyValueTo,
  operationType
}: ForgerInput) => {
  const params = {
    TableName,
    IndexName: IndexName !== "table" ? IndexName : undefined,
    KeyConditionExpression: `#DYNOBASE_${keySchema[0].AttributeName} = :pkey`,
    ExpressionAttributeValues: {
      ":pkey": partitionKeyValue
    } as any,
    ExpressionAttributeNames: {
      [`#DYNOBASE_${keySchema[0].AttributeName}`]: keySchema[0].AttributeName
    },
    ScanIndexForward,
    ExclusiveStartKey,
    FilterExpression: ""
  };

  if (operationType === "SCAN") {
    delete params.ExpressionAttributeNames;
    delete params.ExpressionAttributeValues;
    delete params.KeyConditionExpression;
  }

  if (keySchema.length > 1 && sortKeyValue) {
    params.ExpressionAttributeValues[":skey"] = sortKeyValue;
    params.ExpressionAttributeNames[`#DYNOBASE_${keySchema[1].AttributeName}`] =
      keySchema[1].AttributeName;
    params.KeyConditionExpression = `${params.KeyConditionExpression} and ${computeSortKeyClause(
      `#DYNOBASE_${keySchema[1].AttributeName}`,
      sortKeyOperator
    )}`;

    if (sortKeyOperator === "between") {
      params.ExpressionAttributeValues[":to_skey"] = sortKeyValueTo;
    }
  }

  const filteredFilterExpressions = filterExpressions
    .filter(x => !!x.attributeName)
    .sort((a, b) => a.createdAt - b.createdAt);

  if (filteredFilterExpressions.length > 0) {
    if (!params.ExpressionAttributeNames) {
      params.ExpressionAttributeNames = {};
      params.ExpressionAttributeValues = {};
    }

    filteredFilterExpressions.forEach(filterExpression => {
      const attrName = filterExpression.attributeName;
      params.ExpressionAttributeNames[`#DYNOBASE_${attrName}`] = attrName;

      if (!filterExpression.operator.includes("Exists")) {
        params.ExpressionAttributeValues[`:${attrName}`] = filterExpression.attributeValue;
      }

      if (params.FilterExpression.length < 2) {
        params.FilterExpression = computePartialFilterExpression(
          filterExpression.attributeName,
          filterExpression.operator
        );
      } else {
        params.FilterExpression = `${params.FilterExpression} ${
          filterExpression.logicalEvaluation
        } ${computePartialFilterExpression(
          filterExpression.attributeName,
          filterExpression.operator
        )}`;
      }
    });
  } else {
    delete params.FilterExpression;
  }

  if (
    params.ExpressionAttributeValues &&
    Object.keys(params.ExpressionAttributeValues).length === 0
  ) {
    delete params.ExpressionAttributeValues;
  }

  return params;
};

export const forgeFilterExpressions = (filterExpressions: FilterExpression[]) => {
  interface StringMap {
    [key: string]: string;
  }

  type Params = {
    ExpressionAttributeValues: StringMap;
    ExpressionAttributeNames: StringMap;
    FilterExpression: string;
  };

  const params: Params = {
    FilterExpression: "",
    ExpressionAttributeValues: {},
    ExpressionAttributeNames: {}
  };

  const filteredFilterExpressions = filterExpressions
    .filter(x => !!x.attributeName)
    .sort((a, b) => a.createdAt - b.createdAt);

  if (filteredFilterExpressions.length > 0) {
    if (!params.ExpressionAttributeNames) {
      params.ExpressionAttributeNames = {};
      params.ExpressionAttributeValues = {};
    }

    filteredFilterExpressions.forEach(filterExpression => {
      const attrName = filterExpression.attributeName;
      params.ExpressionAttributeNames[`#DYNOBASE_${attrName}`] = attrName;

      if (!filterExpression.operator.includes("Exists")) {
        params.ExpressionAttributeValues[`:${attrName}`] = filterExpression.attributeValue;
      }

      if (params.FilterExpression.length < 2) {
        params.FilterExpression = computePartialFilterExpression(
          filterExpression.attributeName,
          filterExpression.operator
        );
      } else {
        params.FilterExpression = `${params.FilterExpression} ${
          filterExpression.logicalEvaluation
        } ${computePartialFilterExpression(
          filterExpression.attributeName,
          filterExpression.operator
        )}`;
      }
    });
  } else {
    delete params.FilterExpression;
  }
};

export default forgeParams;
