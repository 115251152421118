import React, { useContext } from "react"
import { Tab } from "../types/Tab"
import {
  FilterExpression,
  AttributeType,
  StringOperator,
  BoolOperator,
  BinaryOperator,
  NumberOperator,
  NullOperator,
} from "../types/FilterExpression"
import { Flex, Text, Box } from "rebass"
import { Input, Select } from "@rebass/forms"
import { Context as FilterExpressionsContext } from "../state/filterExpressions"
import RemoveIcon from "./icons/Remove"

interface SimpleFilterProps {
  tab: Tab
}

const getOperators = (attributeType: AttributeType) => {
  switch (attributeType) {
    case AttributeType.String:
      return Object.keys(StringOperator)
    case AttributeType.Boolean:
      return Object.keys(BoolOperator)
    case AttributeType.Binary:
      return Object.keys(BinaryOperator)
    case AttributeType.Number:
      return Object.keys(NumberOperator)
    case AttributeType.Null:
      return Object.keys(NullOperator)
  }
}

const renderFilterExpression = (
  filterExpression: FilterExpression,
  changeFilterExpression: (id: string, arg2: any) => void,
  removeFilterExpression: (id: string) => void,
  tab: Tab
) => {
  return (
    <Flex my={1} alignItems={"center"} key={filterExpression.id} mb={1}>
      <Input
        name="name"
        autoComplete="off"
        style={{ border: "1px solid rgb(213, 213, 213)", outline: "none" }}
        fontSize="16px"
        height="36px"
        fontFamily="system-ui"
        value={filterExpression.attributeName}
        width={1 / 3}
        placeholder="Field name"
        onChange={(e: any) => {
          changeFilterExpression(filterExpression.id, {
            ...filterExpression,
            attributeName: e.target.value,
          })
        }}
      />
      {!filterExpression.queryIndexName && (
        <Select
          width="135px"
          mx={16}
          fontSize="16px"
          height="36px"
          fontFamily="system-ui"
          p={1}
          value={filterExpression.operator}
          style={{
            outline: "none",
            borderRadius: 0,
            borderColor: "rgb(213, 213,213)",
          }}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            changeFilterExpression(filterExpression.id, {
              ...filterExpression,
              operator: e.target.value,
            })
          }}
        >
          {getOperators(filterExpression.attributeType).map(
            (operator: string) => (
              <option
                value={StringOperator[operator as keyof typeof StringOperator]}
                key={operator}
              >
                {StringOperator[operator as keyof typeof StringOperator]}
              </option>
            )
          )}
        </Select>
      )}
      {!!filterExpression.queryIndexName && (
        <span style={{ margin: "8px 77px" }}> = </span>
      )}

      <Input
        id="value"
        name="value"
        fontSize="16px"
        height="36px"
        autoComplete="off"
        value={filterExpression.attributeValue}
        placeholder="Enter value"
        width={1 / 3}
        style={{
          marginRight: "10px",
          outline: "none",
          borderColor: "rgb(213, 213,213)",
        }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          changeFilterExpression(filterExpression.id, {
            ...filterExpression,
            attributeValue: e.target.value,
          })
        }}
      />
      <RemoveIcon onClick={() => removeFilterExpression(filterExpression.id)} />
    </Flex>
  )
}

const SimpleFilter: React.FC<SimpleFilterProps> = (
  props: SimpleFilterProps
) => {
  const { dispatch, state } = useContext(FilterExpressionsContext)
  const changeFilterExpression = (id: string, newFilterExpression: any) => {
    dispatch({ type: "CHANGE_FILTER_EXPRESSION", id, newFilterExpression })
  }

  const removeFilterExpression = (id: string) => {
    dispatch({ type: "REMOVE_FILTER_EXPRESSION", id })
  }

  return (
    <div>
      {state.filterExpressions.map((expr: FilterExpression) =>
        renderFilterExpression(
          expr,
          changeFilterExpression,
          removeFilterExpression,
          props.tab
        )
      )}
      <Text
        ml={2}
        mt={3}
        mb={3}
        onClick={() => dispatch({ type: "ADD_NEW_FILTER_EXPRESSION" })}
        fontSize={"12px"}
        style={{ cursor: "pointer", width: "max-content", color: "#5b5b5b" }}
      >
        + Add another filter
      </Text>
      {state.usedIndex && (
        <Text my={1} color={"#adadad"} fontSize={"10px"}>
          Using index "{state.usedIndex.IndexName}" to perform Query instead of
          Scan
          {state.usableIndexes!.length > 1 &&
            state
              .usableIndexes!.filter(
                i =>
                  state.usedIndex && i.IndexName !== state.usedIndex.IndexName
              )
              .map(ind => (
                <Box
                  key={ind.IndexName}
                  mx={1}
                  p={1}
                  onClick={() =>
                    dispatch({ type: "USE_INDEX", indexName: ind.IndexName })
                  }
                  style={{
                    display: "inline",
                    cursor: "pointer",
                    backgroundColor: "#f5f5f5",
                  }}
                >
                  Use index "{ind.IndexName}" instead
                </Box>
              ))}
        </Text>
      )}
    </div>
  )
}

export default SimpleFilter
